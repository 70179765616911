<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Type</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Type">
                <v-select
                  v-model="type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[
                    'Other',
                    'Supplier',
                    'Customer',
                    'Bank',
                    'Cash',
                    'Asset',
                    'Dealer',
                    'Employee',
                  ]"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Account Group</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Account Group"
              >
                <v-select
                  v-model="accountgrp"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="accgroupOption"
                  placeholder="None"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Title</label>
              <!-- <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Title"
              > -->
              <v-select
                v-model="title"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['DR.', 'MRS.', 'M/S.', 'MS.', 'Mr.', 'Er.', 'Prof.', 'Shri']"
                placeholder="None"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Ledger Name"
              >
                <label>Ledger Name</label>
                <label style="color: red !important">*</label>
                <b-form-input v-model="name" placeholder="Enter Ledger Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <!-- <validation-provider
                #default="{ errors }"
                rules="required"
                name="Mobile"
              > -->
              <label>Mobile</label>
              <!-- <label style="color: red !important">*</label> -->
              <b-form-input v-model="mobile" placeholder="Enter Mobile" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <!-- <label style="color: red !important">*</label> -->
              <!-- <validation-provider
                #default="{ errors }"
                rules="required"
                name="Email"
              > -->
              <b-form-input
                v-model="email"
                type="email"
                placeholder="Enter Email Address"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Alias</label>
              <!-- <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Alias"
              > -->
              <b-form-input v-model="alias" placeholder="Enter Alias" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Registration Type</label>
              <!-- <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Registration"
              > -->
              <v-select
                v-model="registrationtype"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['None', 'Compositions', 'Regular', 'Consumer', 'Unregistered']"
                placeholder="None"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>GSTIN NO</label>
              <b-form-input
                v-model="gstno"
                maxLength="15"
                @input="validateGSTClick($event)"
                placeholder="Enter GSTIN NO"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <b-form-input
                v-model="panno"
                placeholder="Enter Pan No"
                @input="validatePanClick($event)"
              />
              <small v-if="pannoValidate == false" class="text-danger">
                Enter Valid PAN No
              </small>
              <!-- </validation-provider> -->
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Birth Date</label>
              <flat-pickr
                v-model="birthdate"
                class="form-control"
                :config="{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                  maxDate: new Date(),
                }"
                style="background: transparent"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Swift Code</label>
              <b-form-input v-model="swiftcode" placeholder="Enter Swift Code" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>IFSC Code</label>
              <b-form-input
                v-model="ifsccode"
                maxlength="11"
                @input="validateIFSCCodeClick($event)"
                placeholder="Enter IFSC Code"
              />
              <small v-if="ifsccodeValidate == false" class="text-danger"
                >Enter Valid IFSC Code</small
              >
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Bank</label>
              <b-form-input v-model="bankname" placeholder="Enter Bank" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <b-form-input
                v-model="bankbranch"
                type="text"
                placeholder="Enter Branch Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Account No</label>
              <b-form-input v-model="accountno" placeholder="Enter Account No" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Tan No</label>
              <b-form-input v-model="tanno" placeholder="Enter Tan No" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Beneficiary Name</label>
              <b-form-input
                v-model="beneficiaryname"
                placeholder="Enter Beneficiary Name"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>TDS %</label>
              <b-form-input v-model="tdsper" placeholder="Enter TDS %" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <b-form-input
                v-model="addressline1"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 1"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <b-form-input
                v-model="addressline2"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 2"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 3</label>
              <b-form-input
                v-model="addressline3"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 3"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <b-form-input
                v-model="pincode"
                maxlength="6"
                @input="clickPincode($event)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                type="number"
                placeholder="Enter Pin Code"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <v-select
                v-model="city"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cityOption"
                @input="clickArea($event)"
                placeholder="None"
                label="City"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <v-select
                v-model="area"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="areaOption"
                placeholder="None"
                @input="handlePincode()"
                label="PostOfficeName"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <v-select
                v-model="state"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="None"
                :options="stateOptions"
                label="State"
                @input="handleCity()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Country</label>
              <b-form-input v-model="country" placeholder="Enter Country" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <label>Active/Inactive</label>
            <label style="color: red !important">*</label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Active/Inactive"
            >
              <v-select
                v-model="status"
                placeholder="None"
                :options="['Active', 'Inactive']"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Contact Person</label>

              <b-form-input v-model="contactpername" placeholder="Enter Contact Person" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Contact Person Number</label>
              <b-form-input
                v-model="contactpermobile"
                maxlength="10"
                placeholder="Enter Contact Number"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Credit period (In Days)</label>
              <b-form-input
                v-model="creditlimitdays"
                maxlength="10"
                placeholder="Enter Credit period (In Days)"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Select Employee</label>
              <!-- <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Employee"> -->
              <v-select
                v-model="employeelist"
                placeholder="None"
                label="name"
                :options="employeeOptions"
              >
                <template #option="{ name, profile_image, username, surname }">
                  <b-avatar :src="getprofileImage(profile_image)" />
                  <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                  ><span>({{ username }})</span>
                </template>
              </v-select>
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="type == 'Bank'">
            <b-form-group>
              <label>QR Code</label>
              <b-form-file
                v-model="qrcode"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'ledger', 'qrcode')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="qrcode" />
          </b-col>

          <!-- <b-col md="4" v-if="ifEdit == true">
            <b-form-group>
              <label>User name (ID)</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="User name"
              >
                <b-form-input
                  v-model="username"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Username"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-else>
            <b-form-group>
              <label>Password</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required|password"
                name="Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="password"
                    placeholder="Enter Password"
                    :type="passwordFieldTypeNew"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="4">
            <b-form-group>
              <label>Photo</label>
              <b-form-file
                v-model="profile_image"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'ledger', 'profile_image')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="profile_image" />
          </b-col>
          <b-col md="4">
            <label>Gender</label>
            <v-select v-model="gender" placeholder="None" :options="['Male', 'Female']" />
          </b-col>
          <b-col md="12">
            <b-form-checkbox v-model="action" inline v-if="type == 'Bank'">
              Show details on sales time
            </b-form-checkbox>
          </b-col>
          <!-- submit button -->
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              :disabled="flag"
              @click.prevent="submitForm"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BInputGroup,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BInputGroupAppend,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../../../components/Attechment.vue";

export default {
  components: {
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BAvatar,
  },
  data() {
    return {
      type: "",
      accountgrp: "",
      title: "",
      name: "",
      mobile: "",
      email: "",
      alias: "",
      registrationtype: "",
      gstno: "",
      panno: "",
      birthdate: "",
      swiftcode: "",
      ifsccode: "",
      bankname: "",
      bankbranch: "",
      accountno: "",
      tanno: "",
      beneficiaryname: "",
      tdsper: "",
      addressline1: "",
      addressline2: "",
      addressline3: "",
      addressline4: "",
      addressline5: "",
      area: "",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      status: "",
      contactpername: "",
      contactpermobile: "",
      creditlimitdays: "",
      rcm: "",
      employeelist: "",
      username: "",
      password: "",
      gender: "",
      test: "",

      areaOption: [],
      accgroupOption: [],
      // username: "",
      // password: "",
      profile_image: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      employeeOptions: [],
      fromState: "",
      stateOptions: [],
      cityOption: [],
      flag: false,
      pannoValidate: true,
      ifsccodeValidate: true,
      passwordFieldTypeNew: "password",
      gstValidate: true,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      qrcode: "",
      action: false,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Ledger") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/accounting/ledger");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/accounting/ledger");
          }
        }
      });
    }
    this.acountGroup();
    this.getState();
    this.getEmployee();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    validateGSTClick() {
      this.gstno = this.gstno.toUpperCase();

      const regex = new RegExp(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
      );
      if (regex.test(this.gstno) == true) {
        this.gstValidate = true;
      } else {
        this.gstValidate = false;
      }
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    async acountGroup() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getchartofaccount`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.accgroupOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
      //   this.areaOption = []
      // this.StateData.map((item) => {
      //   if (item.City == e) {
      //     this.areaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }

      if (!this.panno) {
        this.pannoValidate = true;
      }
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.areaOption = [];
      this.cityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handlePincode() {
      if (this.fromState == true) {
        this.pincode = this.area.Pincode;
      }
    },
    async getEmployee(id) {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      }).then((response) => {
        this.employeeOptions = response.data.data;
        if (id !== undefined) {
          this.employeeOptions.map((item) => {
            if (item.id == id) {
              this.employeelist = item;
            }
          });
        }
      });
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      data.map((item) => {
        this.type = item.type;
        this.accountgrp = item.groups;
        this.title = item.title;
        this.name = item.name;
        this.mobile = item.mobile;
        this.test = item.email;
        this.email = item.email;
        this.alias = item.alias;
        this.registrationtype = item.registrationtype;
        this.gstno = item.gstno;
        this.panno = item.panno;
        this.birthdate = item.birthdate == "00/00/0000" ? null : item.birthdate;
        this.swiftcode = item.swiftcode;
        this.ifsccode = item.ifsccode;
        this.bankname = item.bankname;
        this.bankbranch = item.bankbranch;
        this.accountno = item.accountno;
        this.tanno = item.tanno;
        this.beneficiaryname = item.beneficiaryname;
        this.tdsper = item.tdsper;
        this.addressline1 = item.addressline1;
        this.addressline2 = item.addressline2;
        this.addressline3 = item.addressline3;
        this.area = item.area;
        this.city = item.city;
        this.state = item.state;
        this.pincode = item.pincode;
        this.country = item.country ? item.country : "India";
        this.status = item.status;
        this.contactpername = item.contactpername;
        this.contactpermobile = item.contactpermobile;
        this.creditlimitdays = item.creditlimitdays;
        this.rcm = item.rcm;
        this.employeelist = item.employee;
        this.username = item.username;
        this.password = item.password;
        this.profile_image = item.profile_image;
        this.gender = item.gender;
        this.action = item.action;
        this.qrcode = item.qrcode;
      });
      if (this.ifEdit == true) {
        delete data.password;
      }
      // this.clickPincode()
      this.handleCity();
      this.handleArea();
    },
    async clickPincode() {
      this.area = "";
      this.city = "";
      this.state = "";
      this.areaOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.pincode)) {
        this.fromState = false;

        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.pincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.cityOption = [];
            this.areaOption = [];
            this.StateData = response.data.data;
            this.areaOption = response.data.data;
            this.state = response.data.data[0];
            this.cityOption.push(response.data.data[0]);

            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.cityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleChangeFile(e, type, name) {
      this.flag = true;
      const vue = this;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions).then((response) => {
        vue[name] = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        this.flag = false;
      });
    },
    submitForm(e) {
      const data = {
        branch: 1,
        company: 1,
        active: 1,
        type: this.type,
        accountgrp: this.accountgrp.id,
        title: this.title,
        name: this.name,
        mobile: this.mobile,
        email: this.email,
        alias: this.alias,
        registrationtype: this.registrationtype,
        gstno: this.gstno,
        panno: this.panno,
        birthdate: this.birthdate,
        swiftcode: this.swiftcode,
        ifsccode: this.ifsccode,
        bankname: this.bankname,
        bankbranch: this.bankbranch,
        accountno: this.accountno,
        tanno: this.tanno,
        beneficiaryname: this.beneficiaryname,
        tdsper: this.tdsper,
        addressline1: this.addressline1,
        addressline2: this.addressline2,
        addressline3: this.addressline3,
        area: this.area?.PostOfficeName,
        city: this.city?.City,
        state: this.state?.State,
        pincode: this.pincode,
        country: this.country,
        status: this.status,
        contactpername: this.contactpername,
        contactpermobile: this.contactpermobile,
        creditlimitdays: this.creditlimitdays,
        rcm: this.rcm,
        employeelist: this.employeelist.id,
        username: this.username,
        password: this.password,
        profile_image: this.profile_image,
        gender: this.gender,
        qrcode: this.qrcode,
        action: this.action,
      };

      if (this.email == this.test) {
        delete data.email;
      }
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        console.log(success == false, this.pannoValidate);
        if (success == false || this.pannoValidate == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all the fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success == true && this.pannoValidate == true) {
          this.flag = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/ledger/${this.$route.params.id}`
              : `${baseApi}/ledger`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // console.log(json, "json====");
              this.$router.push("/master/account/ledger");
            })
            .catch((error) => {
              this.flag = false;
              let html = "";
              if (
                error.response &&
                error.response.data &&
                error.response.data.message &&
                error.response.data.message.original
              ) {
                Object.values(error.response.data.message.original).map((item) => {
                  item.map((inner) => {
                    html += `${inner} \n`;
                  });
                });
              }
              this.$swal({
                title: "Error!",
                icon: "error",
                html: html,
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/master/account/ledger");
    },
  },
};
</script>
